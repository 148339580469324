import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import katex from "katex";
import "katex/dist/katex.min.css";

// Import Quill modules
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

// Advanced clipboard handling for sub/sup
function registerClipboardMatchers() {
  // Get default matchers
  const matchers = Clipboard.DEFAULTS.matchers;

  // Helper function to create format delta
  const createScriptDelta =
    (type: "super" | "sub") => (node: HTMLElement, delta: any) => {
      return delta.compose(
        new Delta().retain(delta.length(), { script: type })
      );
    };

  // Direct tag matchers
  matchers.push(["sup", createScriptDelta("super")]);
  matchers.push(["sub", createScriptDelta("sub")]);

  // Style-based matcher for vertical-align
  matchers.push([
    "span",
    (node: HTMLElement, delta: any) => {
      const style = node.style.verticalAlign;
      if (style === "super") return createScriptDelta("super")(node, delta);
      if (style === "sub") return createScriptDelta("sub")(node, delta);
      return delta;
    },
  ]);

  // Script tag matcher
  matchers.push([
    "script",
    (node: HTMLElement, delta: any) => {
      const type = node.getAttribute("type");
      if (type === "text/superscript")
        return createScriptDelta("super")(node, delta);
      if (type === "text/subscript")
        return createScriptDelta("sub")(node, delta);
      return delta;
    },
  ]);

  return matchers;
}

interface HtmlEditorWithPreviewProps {
  html: string;
  onHtmlChange: (html: string) => void;
}

const HtmlEditorWithPreview = ({
  html,
  onHtmlChange,
}: HtmlEditorWithPreviewProps) => {
  const [uploadImage] = useUploadImageMutation();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const quillRef = useRef<ReactQuill>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => setIsInitialRender(false), []);

  const handleFormula = () => {
    const displayMode = window.confirm(
      "Display mode? (OK for block, Cancel for inline)"
    );
    const defaultText = displayMode
      ? "\\[\n\\begin{aligned}\n2x + 3y &= 6 \\\\\n4x - y &= 5\n\\end{aligned}\n\\]"
      : "\\( x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a} \\)";

    const formula = window.prompt("Enter LaTeX formula:", defaultText);
    if (formula) {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const range = quill.getSelection();
        if (range) {
          quill.clipboard.dangerouslyPasteHTML(range.index, formula, "api");
          quill.setSelection(range.index + formula.length, 0);
        }
      }
    }
  };

  const decodeEntities = (text: string) => {
    const temp = document.createElement("textarea");
    temp.innerHTML = text;
    return temp.value;
  };

  useEffect(() => {
    if (previewRef.current) {
      let content = decodeEntities(html);

      // First clean up any p tags wrapping LaTeX content
      content = content.replace(/<p>\s*(\\\[[\s\S]*?\\\])\s*<\/p>/g, "$1");

      // Replace display math \[...\] with rendered KaTeX
      content = content.replace(/\\\[([\s\S]*?)\\\]/g, (match, latex) => {
        try {
          const rendered = katex.renderToString(latex.trim(), {
            displayMode: true,
            trust: true,
            strict: false,
            output: "html",
          });
          return `<div class="katex-wrapper">${rendered}</div>`;
        } catch (e: any) {
          console.error("KaTeX display error:", e.message);
          return `<div style="color: red;">KaTeX Error: ${e.message}</div>`;
        }
      });

      // Replace inline math \(...\) with rendered KaTeX
      content = content.replace(/\\\(([\s\S]*?)\\\)/g, (match, latex) => {
        try {
          return katex.renderToString(latex.trim(), {
            displayMode: false,
            trust: true,
            strict: false,
            output: "html",
          });
        } catch (e: any) {
          console.error("KaTeX inline error:", e.message);
          return `<span style="color: red;">KaTeX Error: ${e.message}</span>`;
        }
      });

      const styledContent = `
        <style>
          html { box-sizing: border-box; font-size: 16px; }
          *, *:before, *:after { box-sizing: inherit; }

          .preview-content {
            background-color: #020016;
            padding: 1.5rem 1rem;
            font-family: 'Arial', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            color: #fff;
            overflow-x: hidden;
          }
             img {
             object-fit: contain;
             margin-bottom: 1rem;
             max-width: 100%;
             display: block;
             margin: 0 auto;
           }

           h1, h2, h3, h4, h5, h6 {
             margin-top: 0;
             margin-bottom: 0.5rem;
             line-height: 1.2;
           }

           h1 { font-size: 2rem; text-align: center; }
           h2 { font-size: 1.75rem; }
           h3 { font-size: 1.5rem; }
           h4 { font-size: 1.25rem; }
           h5 { font-size: 1.125rem; }
           h6 { font-size: 1rem; }

           p {
             margin-top: 0;
             margin-bottom: 1rem;
             word-wrap: break-word;
           }

           a {
             color: #007bff;
             text-decoration: none;
           }

           a:hover {
             text-decoration: underline;
           }

           ul, ol {
             margin-top: 0;
             margin-bottom: 2rem;
             padding-left: 1rem;
           }

           ul ul {
             list-style-type: square;           }

          .katex-display {
            margin: 0 !important;
            overflow-x: auto;
            overflow-y: hidden;
          }

          .katex-wrapper {
            display: block;
            text-align: center;
            padding: 0.5em 0;
            overflow-x: auto;
            overflow-y: hidden;
          }

          .katex {
            color: #fff;
            text-indent: 0;
          }

          sup, .sup {
            vertical-align: super;
            font-size: smaller;
          }

          sub, .sub {
            vertical-align: sub;
            font-size: smaller;
          }
        </style>
        <div class="preview-content">${content}</div>
      `;

      previewRef.current.innerHTML = styledContent;
    }
  }, [html]);

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "color",
      "background",
      "script",
    ],
    []
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["blockquote"],
          ["image"],
          [{ script: "sub" }, { script: "super" }],
          ["formula"],
        ],
        handlers: {
          formula: handleFormula,
        },
      },
      clipboard: {
        matchVisual: false,
        matchers: registerClipboardMatchers(),
      },
      imageUploader: {
        upload: async (file: File) => {
          const formData = new FormData();
          formData.append("file", file);
          try {
            const result = await uploadImage({
              formData: formData,
              prefix: "quests",
            }).unwrap();
            return result.url;
          } catch (error) {
            console.error("Failed to upload image", error);
          }
        },
      },
    }),
    [uploadImage]
  );

  return (
    <Container>
      <LeftPanel className="editor-container">
        {!isInitialRender && (
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={html}
            modules={modules}
            onChange={onHtmlChange}
            formats={formats}
            preserveWhitespace={true}
          />
        )}
      </LeftPanel>
      <RightPanel>
        <MobilePreview>
          <div
            ref={previewRef}
            className="overflow-scroll h-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          />
        </MobilePreview>
      </RightPanel>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  width: 100%;
  height: 95%;
  display: flex;
  column-gap: 32px;
`;

const LeftPanel = styled.div`
  flex: 1.5;
  background-color: #f3f4f6;
`;

const RightPanel = styled.div`
  flex: 1;
`;

const MobilePreview = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 375px;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export default HtmlEditorWithPreview;