import { useEffect, useState } from "react";
import {
  useGetQuestionsCatalogueQuery,
  useUpdateQuestionMutation,
} from "../questions/QuestionsApiSlice";
import {
  useAddSlideMutation,
  useCreateChapterMutation,
  useDeleteSlideMutation,
  useGetChaptersQuery,
  useUpdateChapterMutation,
  useUpdateSlideMutation,
} from "./ChapterApiSlice";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { uniqueId } from "lodash";
import {
  Button,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useChapterQuestions } from "./hooks/useChapterQuestions";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { ChapterFilters } from "./types";
import { ChapterProvider } from "./contexts/ChapteContext";
import { ThreeDots } from "react-loader-spinner";
import { CurriculumBoard } from "../EditTopics/EditTopicsApiSlice";
import StatementEditor from "../cases/components/StatementEditor";
import QuestionListChapters from "./QuestionListChapters";
import {
  QuestionEditorDrawer,
  QuestionSelectorDrawer,
} from "./components/QuestionDrawers";
import { EmptyStateContent } from "./components/EmptyStatContent";
import TopicSelector from "../../components/dash-components/TopicSelector";
import { NewQuestionDrawer } from "./components/NewQuestionDrawer";

const ShowCapters = () => {
  const [selectedFilter, setSelectedFilter] = useState<ChapterFilters>({
    topicId: null,
    subtopicId: null,
  });
  const [selectedClass, setSelectedClass] = useState<string>();
  const [showQuestionsDrawer, setShowQuestionsDrawer] = useState(false);
  const [isEditingQuestions, setIsEditingQuestions] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<IMCQQuestion>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedQuestions, setSelectedQuestions] = useState<IMCQQuestion[]>(
    []
  );
  const [isAddSlideModalOpen, setIsAddSlideModalOpen] = useState(false);
  const [newSlideText, setNewSlideText] = useState("");
  const [modalSelectedQuestions, setModalSelectedQuestions] = useState<
    IMCQQuestion[]
  >([]); // New state for modal question selection
  const [isModalQuestionsDrawerOpen, setIsModalQuestionsDrawerOpen] =
    useState(false);
  const [allConceptTags, setAllConceptTags] = useState<any[]>([]);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [isInitialState, setIsInitialState] = useState(true);
  const [isNewQuestionDrawerOpen, setIsNewQuestionDrawerOpen] = useState(false);
  const [curriculumBoard, setCurriculumBoard] = useState<CurriculumBoard | "">(
    CurriculumBoard.ICSE
  );
  const [slideQuestions, setSlideQuestions] = useState<IMCQQuestion[]>([]);
  const [isUpdatingSlide, setIsUpdatingSlide] = useState(false); //Flag for updating
  const [selectedSlideForQuestions, setSelectedSlideForQuestions] = useState<
    string | null
  >(null);
  const [selectedConceptTag, setSelectedConceptTag] = useState<
    string | undefined
  >(undefined); // Track selected conceptTag (single select)
  const [isSlideQuestionsDrawerOpen, setIsSlideQuestionsDrawerOpen] =
    useState(false);
  const {
    data: classData,
    isLoading: isClassDataLoading,
    isFetching: isClassDataFetching,
    isSuccess: isClassDataSuccess,
  } = useGetQuestionsCatalogueQuery({
    curriculumBoard: curriculumBoard as CurriculumBoard,
  });
  const [addSlide] = useAddSlideMutation();
  const [deleteSlide] = useDeleteSlideMutation();
  const [updateSlide] = useUpdateSlideMutation();

  const [saveQuestion] = useUpdateQuestionMutation();
  const [uploadAsset] = useUploadImageMutation();
  const [updateChapter] = useUpdateChapterMutation();
  const [createChapter] = useCreateChapterMutation();
  const handleConceptTagChange = (value: string) => {
    setSelectedConceptTag(value); // Update the selected concept tag
  };
  const { questions, setQuestions, refetch } = useChapterQuestions(
    allConceptTags,
    selectedFilter,
    curriculumBoard as CurriculumBoard
  );

  const handleChange = (value: CurriculumBoard | "") => {
    setCurriculumBoard(value);
  };
  const handleAddSlide = async () => {
    try {
      if (!newSlideText || !questions[currentChapterIndex]._id) return;
      await addSlide({
        chapterId: questions[currentChapterIndex]._id,
        slideData: {
          text: newSlideText,
          questionIds: modalSelectedQuestions.map((q) => q.id),
          conceptId: selectedConceptTag,
        },
      }).unwrap();
      setNewSlideText("");
      setModalSelectedQuestions([]); // Clear selected questions in modal
      setSelectedConceptTag(undefined); // Clear selected concept tag
      await refetch();
      message.success("Slide added successfully!");
      setIsAddSlideModalOpen(false);
    } catch (error) {
      console.error("Failed to add slide:", error);
      message.error("Failed to add slide.");
    }
  };

  const handleDeleteSlide = async (slideId: string) => {
    try {
      if (!questions[currentChapterIndex]._id) return;

      // Show confirmation modal
      Modal.confirm({
        title: "Are you sure you want to delete this slide?",
        content: "This action cannot be undone.",
        okText: "Yes, Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk: async () => {
          // Proceed with deletion if confirmed
          await deleteSlide({
            chapterId: questions[currentChapterIndex]._id as string,
            slideId,
          }).unwrap();
          await refetch();
          message.success("Slide deleted successfully!");
        },
        onCancel: () => {
          message.info("Slide deletion cancelled.");
        },
      });
    } catch (error) {
      console.error("Failed to delete slide:", error);
      message.error("Failed to delete slide.");
    }
  };

  const handleConceptTagInSlides = async (
    slideId: string,
    conceptId: string
  ) => {
    try {
      if (!questions[currentChapterIndex]._id) return;

      // Prepare the updateData for conceptId
      const updateData: { conceptId?: string } = {};
      if (conceptId) updateData.conceptId = conceptId;

      // Only call updateSlide if there's valid updateData
      if (Object.keys(updateData).length > 0) {
        await updateSlide({
          chapterId: questions[currentChapterIndex]._id as string,
          slideId,
          updateData,
        }).unwrap();
        await refetch();
        message.success("Slide concept updated successfully!");
      } else {
        message.info("No changes detected.");
      }

      setIsUpdatingSlide(false);
    } catch (error) {
      console.error("Failed to update slide concept:", error);
      message.error("Failed to update slide concept.");
    }
  };

  const handleUpdateSlides = async (
    slideId: string,
    updatedText?: string,
    updatedQuestionsIds?: string[]
  ) => {
    try {
      if (!questions[currentChapterIndex]._id) return;

      // Prepare the updateData conditionally
      const updateData: { text?: string; questionIds?: string[] } = {};
      if (updatedText) updateData.text = updatedText;
      if (updatedQuestionsIds) updateData.questionIds = updatedQuestionsIds;

      // Only call updateSlide if there's valid updateData
      if (Object.keys(updateData).length > 0) {
        await updateSlide({
          chapterId: questions[currentChapterIndex]._id as string,
          slideId,
          updateData,
        }).unwrap();
        await refetch();
        message.success("Slide updated successfully!");
      } else {
        message.info("No changes detected.");
      }

      setIsUpdatingSlide(false);
    } catch (error) {
      console.error("Failed to update slide:", error);
      message.error("Failed to update slide.");
    }
  };

  const nextChapter = () => {
    if (questions.length > 0) {
      setCurrentChapterIndex((prev) =>
        Math.min(prev + 1, questions.length - 1)
      );
    }
  };

  const prevChapter = () => {
    if (questions.length > 0) {
      setCurrentChapterIndex((prev) => Math.max(prev - 1, 0));
    }
  };

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || [];
    const classId = selection.classId || undefined;
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;

    setAllConceptTags(concepts);
    setSelectedFilter({ topicId, subtopicId });
    setSelectedClass(classId);
  };

  const saveUpdatedChapter = async (
    chapterIndex: number,
    updatedSlides: string[]
  ) => {
    const updatedChapter = {
      ...questions[chapterIndex],
      slides: updatedSlides,
    };

    try {
      await updateChapter({
        chapterId: updatedChapter._id!,
        chapterData: { en_content: updatedChapter.en_content },
      });
      await refetch();
      message.success("Chapter updated successfully");
    } catch (error) {
      console.error("Failed to update chapter", error);
      message.error("Failed to update chapter");
    }
  };

  const updateChapterQuestion = async (data: any) => {
    try {
      // Determine the ID field, prioritizing `id` over `_id`
      const questionId = data.id || data._id;

      if (!questionId) {
        message.error("Question ID is missing.");
        return;
      }

      // Prepare dataToUpdate
      const dataToUpdate = { ...data };

      // Handle conceptTags: ensure it's an array of IDs
      if (Array.isArray(data.conceptTags)) {
        dataToUpdate.conceptTags = data.conceptTags.map((item: any) =>
          typeof item === "object" && item.id ? item.id : item
        );
      }

      // Remove problemStatement from the update data
      delete dataToUpdate.problemStatement;

      console.log("dataToUpdate:", dataToUpdate);

      // Save the question with the determined ID
      const result = await saveQuestion({
        id: questionId,
        data: dataToUpdate,
      }).unwrap();

      await refetch();
      message.success("Question updated successfully");
      setIsEditingQuestions(false);
    } catch (error) {
      console.error("Failed to update question", error);
      message.error("Failed to update question");
    }
  };

  const updateChapterQuestions = (questionsToUpdate: IMCQQuestion[]) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((chapter: any, idx: number) => {
        if (idx === selectedIndex) {
          return {
            ...chapter,
            slides: [...(chapter.slides || []), ...questionsToUpdate],
          };
        }
        return chapter;
      })
    );
    setSelectedQuestions([]);
  };

  const deleteChapterQuestion = (questionId?: string) => {
    // setQuestions((prevQuestions) =>
    //   prevQuestions.map((chapter) => ({
    //     ...chapter,
    //     slides: (chapter.slides || []).filter((q) => q.id !== questionId),
    //   }))
    // );
    // setIsEditingQuestions(false);
    console.log("Deleting question with id:------------------", questionId);
    Modal.confirm({
      title: "Are you sure you want to delete this question?",
      content: "This action cannot be undone.",
      onOk() {
        deleteQuestionImpl(questionId);
      },
    });
  };

  const deleteQuestionImpl = async (questionId?: string) => {
    if (!selectedSlideForQuestions) return;
    console.log("Deleting question with id:", questionId);
    try {
      const currentSlide = questions[currentChapterIndex].slides.find(
        (slide: any) => slide.id === selectedSlideForQuestions
      );
      const existingQuestionIds = (currentSlide?.questionIds || [])
        .map((q: any) => q._id)
        .filter((_id: string) => _id !== questionId);
      await handleUpdateSlides(
        selectedSlideForQuestions,
        currentSlide?.text as string,
        existingQuestionIds
      );
      message.success("Question deleted successfully!");
      setIsEditingQuestions(false);
    } catch (error) {
      console.error("Failed to delete question:", error);
      message.error("Failed to delete question.");
    }
  };

  const openQuestionEditor = (setEdit: boolean = false) => {
    if (setEdit) {
      const newQuestionData: IMCQQuestion = {
        id: uniqueId(),
        en_question: [],
        en_options: [],
        topic: selectedFilter.topicId || undefined,
        subtopic: selectedFilter.subtopicId || undefined,
        allConceptTags: [],
        explaination: "",
        type: "mcq",
        formatType: "FOUR_OPTIONS",
        status: "pending",
        meta: [],
      };
      setEditingQuestion(newQuestionData);
    }
    setIsEditingQuestions(true);
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  const handleAddNewQuestionsForSlide = async (
    newQuestions: IMCQQuestion[]
  ) => {
    if (!selectedSlideForQuestions) return;

    // Extract new question IDs, ensuring unique IDs are generated for each question
    const newQuestionIds = newQuestions.map((q) => q.id || uniqueId());

    try {
      // Find the current slide associated with the selected slide ID
      const currentSlide = questions[currentChapterIndex].slides.find(
        (slide: any) => slide.id === selectedSlideForQuestions
      );
      console.log("Current slide:", currentSlide);

      // Get existing question IDs from the current slide
      const existingQuestionIds = (currentSlide?.questionIds || [])?.map(
        (q: any) => q._id
      );

      // Combine existing and new question IDs while avoiding duplicates
      const uniqueQuestionIds = Array.from(
        new Set([...existingQuestionIds, ...newQuestionIds])
      );

      // Update the slide with the new list of unique question IDs
      await handleUpdateSlides(
        selectedSlideForQuestions,
        currentSlide?.text as string,
        uniqueQuestionIds
      );

      message.success("Questions added successfully!");
    } catch (error) {
      console.error("Failed to add questions:", error);
      message.error("Failed to add questions.");
    } finally {
      setSelectedQuestions([]);
      setSlideQuestions([]);
    }
  };

  useEffect(() => {
    if (selectedFilter.topicId && selectedFilter.subtopicId) {
      setIsInitialState(false);
    }
  }, [selectedFilter]);
  const deleteModalSelectedQuestions = (id?: string) => {
    // Filter out the question with the provided id from the modalSelectedQuestions array
    console.log("Deleting question with id:", id);
    const updatedQuestions = modalSelectedQuestions.filter((q) => q.id !== id);

    // Update the modalSelectedQuestions state
    setModalSelectedQuestions(updatedQuestions);

    // message.success("Question deleted successfully.");
  };

  const showEmptyState = !isInitialState && questions.length === 0;

  const renderContent = () => {
    if (isInitialState) {
      return <EmptyStateContent />; // Replace with your actual component
    }
    if (showEmptyState) {
      return (
        <Empty
          className="mt-8 bg-white rounded-lg p-8"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <div className="space-y-2">
              <p className="text-gray-600">No chapters found</p>
              <p className="text-sm text-gray-400">
                Try selecting different filters
              </p>
            </div>
          }
        />
      );
    }
    return (
      <div className="mt-8 max-w-7xl mx-auto ">
        {questions.length > 0 && (
          <>
            <div className="bg-white rounded-t-lg shadow-sm border-b border-gray-200 p-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">
                  Chapter: {questions[currentChapterIndex]?.title}
                </h2>
                <div className="flex items-center space-x-3">
                  <Button
                    icon={<LeftOutlined />}
                    onClick={prevChapter}
                    disabled={currentChapterIndex === 0}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={nextChapter}
                    disabled={currentChapterIndex === questions.length - 1}
                  >
                    Next <RightOutlined className="ml-1" />
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() => setIsAddSlideModalOpen(true)}
                    icon={<PlusOutlined />}
                  >
                    Add Slide
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-b-lg p-6">
              {questions[currentChapterIndex].slides.map((slide, index) => (
                <div key={index} className="flex gap-6 mb-6 border-2 pr-4 rounded">
                  {/* Left Side - Slide Content */}
                  <div className="flex-1 border-r border-gray-300 p-4 rounded">
                    <div className="flex items-start justify-between">
                      <div className=" w-full">
                        <StatementEditor
                          initialData={slide?.text}
                          onSave={(updatedContent) =>
                            handleUpdateSlides(slide.id, updatedContent[0])
                          }
                        />
                      </div>
                      <Space>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteSlide(slide.id)}
                        >
                          Delete Slide
                        </Button>
                      </Space>
                    </div>
                  </div>
                  <div className="flex-shrink-0 w-1/3">
                    <div className="mt-4 flex flex-col gap-6">
                      <div className="mt-4">
                        <p className="text-sm text-gray-500 mb-2">
                          Select Concept Tag
                        </p>
                        <Select
                          value={slide.conceptId} // Display the current conceptId value
                          onChange={(value) =>
                            handleConceptTagInSlides(slide.id, value)
                          } // Call handleUpdateSlide on change
                          style={{ width: "100%" }}
                          placeholder="Select a concept tag"
                        >
                          {allConceptTags.map((concept) => (
                            <Select.Option key={concept.id} value={concept.id}>
                              {concept.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button
                          type="primary"
                          onClick={() => setSelectedSlideForQuestions(slide.id)}
                        >
                          Edit/Delete Questions
                        </Button>
                        {selectedSlideForQuestions !== slide.id && (
                          <p className="text-xs text-gray-500 mt-1 inline-flex items-center">
                            <InfoCircleOutlined className="mr-1" />
                            Click "Edit/Delete Questions" to enable delete
                            functionality.
                          </p>
                        )}
                      </div>
                      <QuestionListChapters
                        slideId={slide.id}
                        questions={slide?.questionIds || []}
                        onEdit={openQuestionEditor}
                        onDelete={deleteChapterQuestion}
                        setEditingQuestion={setEditingQuestion}
                        disableDelete={selectedSlideForQuestions !== slide.id}
                      />
                      <Space>
                        <Button
                          type="primary"
                          icon={<PlusCircleOutlined />}
                          onClick={() => {
                            setSelectedSlideForQuestions(slide.id);
                            setIsSlideQuestionsDrawerOpen(true);
                          }}
                        >
                          Add Existing Questions
                        </Button>
                        <Button
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          onClick={() => {
                            setSelectedSlideForQuestions(slide.id);
                            setIsNewQuestionDrawerOpen(true);
                          }}
                        >
                          Create New Question
                        </Button>
                      </Space>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <ChapterProvider>
      <div className="p-4 max-w-7xl mx-auto">
        <div className="flex">
          <Select
            style={{ width: 200 }}
            value={curriculumBoard}
            onChange={handleChange}
            placeholder="Select Curriculum Board"
            className="mr-2"
          >
            <Select.Option key="all" value="">
              All
            </Select.Option>
            {Object.values(CurriculumBoard).map((board) => (
              <Select.Option key={board} value={board}>
                {board}
              </Select.Option>
            ))}
          </Select>
          <div className="relative">
            {classData && isClassDataSuccess && !isClassDataFetching ? (
              <TopicSelector
              classes={classData || []}
              onChange={updateFilter}
            />
            ) : (
              
              <div className="flex justify-center items-center bg-white rounded-lg ">
              {/* <Spin indicator={antIcon} tip="Loading class data..." /> */}
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#1890ff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
            )}
          </div>
        </div>

        {renderContent()}

        <QuestionEditorDrawer //Your component
          open={isEditingQuestions}
          onClose={() => setIsEditingQuestions(false)}
          question={editingQuestion}
          onSave={updateChapterQuestion}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
        />
        <QuestionSelectorDrawer
          open={isSlideQuestionsDrawerOpen}
          onClose={() => setIsSlideQuestionsDrawerOpen(false)}
          selectedQuestions={slideQuestions}
          setSelectedQuestions={setSlideQuestions}
          selectedClass={selectedClass}
          selectedFilter={selectedFilter}
          onSubmit={() => {
            handleAddNewQuestionsForSlide(slideQuestions);
            setIsSlideQuestionsDrawerOpen(false);
          }}
          curriculumBoard={curriculumBoard}
        />

        <NewQuestionDrawer //Your component
          isOpen={isNewQuestionDrawerOpen}
          onClose={() => setIsNewQuestionDrawerOpen(false)}
          onSave={handleAddNewQuestionsForSlide}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
          topicId={selectedFilter.topicId}
          subtopicId={selectedFilter.subtopicId}
          allConceptTags={allConceptTags}
        />
      </div>
      <Modal
        width={800}
        title="Add/Update Slide"
        open={isAddSlideModalOpen}
        onCancel={() => {
          setIsAddSlideModalOpen(false);
          setModalSelectedQuestions([]); // Clear selection on cancel
        }}
        onOk={handleAddSlide}
        confirmLoading={isUpdatingSlide}
      >
        <Form>
          <Form.Item
            label="Slide Text"
            help="Ensure that the slide text is formatted as HTML for proper rendering."
            className="mb-9"
          >
            <Input.TextArea
              value={newSlideText}
              onChange={(e) => setNewSlideText(e.target.value)}
              rows={4}
              className=""
            />
          </Form.Item>
          <div className="mt-4">
            <Form.Item label="Concept Tag" >
              <Select
                className=""
                value={selectedConceptTag}
                onChange={handleConceptTagChange}
                options={allConceptTags.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                }))}
                placeholder="Select a concept tag"
              />
            </Form.Item>
          </div>
          <QuestionListChapters
            // slideId={slide.id}
            questions={modalSelectedQuestions || []}
            // onEdit={openQuestionEditor}
            onDelete={deleteModalSelectedQuestions}
            setEditingQuestion={setEditingQuestion}
            disableDelete={false}
          />
          <Form.Item label="Select Questions" className="mt-4">
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => setIsModalQuestionsDrawerOpen(true)}
              className=""
            >
              Add Existing Questions
            </Button>
            <QuestionSelectorDrawer // Your component, but now modal-specific
              open={isModalQuestionsDrawerOpen}
              onClose={() => setIsModalQuestionsDrawerOpen(false)}
              selectedQuestions={modalSelectedQuestions}
              setSelectedQuestions={setModalSelectedQuestions}
              selectedClass={selectedClass}
              selectedFilter={selectedFilter}
              onSubmit={() => setIsModalQuestionsDrawerOpen(false)} // Close drawer on submit
              curriculumBoard={curriculumBoard}
            />
          </Form.Item>
        </Form>
      </Modal>
    </ChapterProvider>
  );
};

export default ShowCapters;

// useEffect(() => {
//   {
//     // Optionally, update modalSelectedQuestions with the allConceptTags if needed
//     setModalSelectedQuestions((prevQuestions) =>
//       prevQuestions.map((question) => ({
//         ...question,
//         allConceptTags: allConceptTags, // Adding allConceptTags to each selected question
//       }))
//     );
//   }
// }, [modalSelectedQuestions]);

// const handleAddOrDeleteQuestions = async (chapterIndex: number) => {
//   try {
//     await updateChapter({
//       chapterId: questions[chapterIndex]._id!,
//       chapterData: { slides: questions[chapterIndex].slides }, // Update slides
//     });
//     await refetch();
//     message.success("Questions updated successfully");
//   } catch (error) {
//     console.error("Failed to update chapter", error);
//     message.error("Failed to update questions");
//   }
// };

//   const updateChapterQuestion = async (data: any) => {
//     try {
//       const dataToUpdate = { ...data };
//       delete dataToUpdate.problemStatement;
// console.log("dataToUpdate:", dataToUpdate);
//       const result = await saveQuestion({
//         id: data._id as string,
//         data: dataToUpdate,
//       }).unwrap();
//       await refetch();
//       message.success("Question updated successfully");
//       setIsEditingQuestions(false);
//     } catch (error) {
//       console.error("Failed to update question", error);
//       message.error("Failed to update question");
//     }
//   };

// const handleDeleteSlide = async (slideId: string) => {
//   try {
//     if (!questions[currentChapterIndex]._id) return;
//     await deleteSlide({
//       chapterId: questions[currentChapterIndex]._id as string,
//       slideId,
//     }).unwrap();
//     await refetch();
//     message.success("Slide deleted successfully!");
//   } catch (error) {
//     console.error("Failed to delete slide:", error);
//     message.error("Failed to delete slide.");
//   }
// };
// const handleUpdateSlides = async (
//   slideId: string,
//   updatedText?: string,
//   updatedQuestionsIds?: string[]
// ) => {
//   try {
//     if (!questions[currentChapterIndex]._id) return;
//     await updateSlide({
//       chapterId: questions[currentChapterIndex]._id as string,
//       slideId,
//       updateData: { text: updatedText, questionIds: updatedQuestionsIds },
//     }).unwrap();
//     await refetch();
//     message.success("Slide updated successfully!");
//     setIsUpdatingSlide(false);
//   } catch (error) {
//     console.error("Failed to update slide:", error);
//     message.error("Failed to update slide.");
//   }
// };
// const handleAddNewQuestionsForSlide = async (
//   newQuestions: IMCQQuestion[]
// ) => {
//   if (!selectedSlideForQuestions) return;
//   const newQuestionIds = newQuestions.map((q) => q.id || uniqueId());
//   try {
//     const currentSlide = questions[currentChapterIndex].slides.find(
//       (slide: any) => slide.id === selectedSlideForQuestions
//     );
//     console.log("Current slide:", currentSlide);

//     const existingQuestionIds = (currentSlide?.questionIds || [])?.map(
//       (q: any) => q._id
//     );
//     await handleUpdateSlides(
//       selectedSlideForQuestions,
//       currentSlide?.text as string,
//       [...existingQuestionIds, ...newQuestionIds]
//     );
//     message.success("Questions added successfully!");
//   } catch (error) {
//     console.error("Failed to add questions:", error);
//     message.error("Failed to add questions.");
//   } finally {
//     setSelectedQuestions([]);
//     setSlideQuestions([]);
//   }
// };

// const handleAddNewQuestionsForSlide = (newQuestions: IMCQQuestion[]) => {
//   if (!selectedSlideForQuestions) return;

//   setQuestions((prevQuestions: any) =>
//     prevQuestions.map((chapter: any) => {
//       if (chapter._id === questions[currentChapterIndex]._id) {
//         return {
//           ...chapter,
//           slides: chapter.slides.map((slide: any) =>
//             slide.id === selectedSlideForQuestions
//               ? {
//                   ...slide,
//                   questionIds: [
//                     ...(slide.questionIds || []),
//                     ...newQuestions.map((q) => ({ ...q, id: q.id || uniqueId() })),
//                   ],
//                 }
//               : slide
//           ),
//         };
//       }
//       return chapter;
//     })
//   );

// };

// Update local state first
// setQuestions((prevQuestions: any) =>
//   prevQuestions.map((chapter: any) => {
//     if (chapter._id === questions[currentChapterIndex]._id) {
//       return {
//         ...chapter,
//         slides: chapter.slides.map((slide: any) =>
//           slide.id === selectedSlideForQuestions
//             ? {
//                 ...slide,
//                 questionIds: [
//                   ...(slide.questionIds || []),
//                   ...newQuestionIds,
//                 ],
//               }
//             : slide
//         ),
//       };
//     }
//     return chapter;
//   })
// );
