import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import Dashboard from "./features/dashboard/Dashboard";
import Home from "./features/home/Home";
import Questions from "./features/questions/Questions";
import Issues from "./features/issues/Issues";
import ClassSubjects from "./features/subjects/Subjects";
import Content from "./features/content/Content";
import Quests from "./features/skills";
import QuestDetails from "./features/skills/QuestStories";
import StoryDetails from "./features/skills/StoryDetails";
import Schools from "./features/schools";
import Leads from "./features/leads";
import Learners from "./features/learners";
import Profile from "./features/learners/Profile";
import PlanBuilder from "./features/leads/PlanBuilder";
import PaymentPlanPage from "./features/leads/PaymentPlan";
import WorksheetScheduler from "./features/schools/WorksheetScheduler";
import ClassroomAnalytics from "./features/schools/Classroom";
import Grades from "./features/schools/SchoolOverview";
import SchoolOverview from "./features/schools/SchoolOverview";
import TeacherOverview from "./features/schools/TeacherOverview";
import Teachers from "./features/schools/Teachers";
import SchedulerOverview from "./features/schools/SchedulerOverview";
import StudentsPage from "./features/schools/StudentsPage";
import StudentFullReport from "./features/schools/StudentFullReport";
import StartSchoolOlympiadForm from "./features/schools/StartSchoolOlympiad";
import Users from "./features/users";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import Importer from "./features/cases/Import";
import Statements from "./features/cases";
import Reviewer from "./features/cases/Reviewer";
import MCQReviewer from "./features/mcq/MCQReviewer";

import SendNotification from "./features/RecentActivities/RecentActivities";

import AcademicWorksheetForm from "./quiz/AcademicWorksheetForm";
import ProfessionCompetitionForm from "./quiz/ProfessionCompetition";
import FlnForm from "./quiz/FlnForm";
import CompetitionForm from "./quiz/CompetitionForms/CompetitionForm";
import AllQuizes from "./quiz/AllQuizes";
import CertificateGeneration from "./features/certificateGeneration/CertificateGeneration";
import CompetitionMetrics from "./features/competitionMetrics/CompetitionMetrics";
import SkillModule from "./features/SkillModule/SkillModule";
import CreateSkillModule from "./features/SkillModule/CreateSkillModule";
import ShowSkillsModule from "./features/SkillModule/showSkillsModule";
import EditTopics from "./features/EditTopics/EditTopics";
import LifeSkill from "./quiz/LifeSkill";
import ShowSkillsModulesCards from "./features/SkillModule/showSkillModulesCards";
import ShowOneSkillModule from "./features/SkillModule/showOneSkillModule";
import CompetitionFormSelectingQuestions from "./quiz/CompetitionForms/CompetitionFormSelectingQuestions";
import CreateTournament from "./features/tournaments/CreateTournament";
import ShowTournaments from "./features/tournaments/ShowTournaments";
import ShowChapters from "./features/chapters/ShowChapters";
import TournamentDetails from "./features/tournaments/TournamentDetails";
import ShowChapterx from "./features/chapters/ShowChaterx";
import QuizModal from "./features/schools/components/QuizModal";
import ShowCapters from "./features/chapters/ShowCapters";

function App() {
  return (
    <Routes>
      {/* Public Route */}
      <Route index element={<Login />} />
      {/* <Route index path="login" element={<Login />} /> */}
      <Route
        path="dashboard/*"
        element={<ProtectedRoute element={<Dashboard />} />}
      >
        {/* Redirect from /dashboard to /dashboard/home */}
        <Route index element={<Navigate to="home" />} />
        <Route path="home" element={<Home />} />

        {/* Statements Routes */}
        <Route path="statements" element={<Statements />} />
        <Route path="statements/import" element={<Importer />} />
        <Route path="statements/review" element={<Reviewer />} />

        <Route path="mcq" element={<MCQReviewer />} />
        {/* <Route path="quiz" element={<AllQuizes />} /> */}
        <Route path="competition" element={<CompetitionForm />} />
        <Route path="competitionSelectQuestions" element={<CompetitionFormSelectingQuestions />} />
        <Route path="academics" element={<AcademicWorksheetForm />} />
        <Route path="profession" element={<ProfessionCompetitionForm />} />
        <Route path="fln" element={<FlnForm />} />
        <Route path="life-skill" element={<LifeSkill />} />
        <Route path="editTopics" element={<EditTopics />} />
        <Route path="clone-quiz" element={<SendNotification />} />
        <Route path="createTournament" element={<CreateTournament />} />
        <Route path="showTournaments" element={<ShowTournaments />} />
        <Route path="showTournaments/:competitionId" element={<TournamentDetails />} />
        <Route path="chapters" element={<ShowCapters/>} />
        <Route
          path="certificateGeneration"
          element={<CertificateGeneration />}
        />
        <Route path="competitionMetrics" element={<CompetitionMetrics />} />
        {/* <Route path="skill-module" element={<SkillModule />} /> */}
        <Route
          path="createSkill"
          element={<CreateSkillModule />}
        />
        <Route
          path="showSkillsModule"
          element={<ShowSkillsModulesCards />}
        />
        <Route 
          path="showSkillsModule/:moduleId"
          element={<ShowOneSkillModule />}
        />

        <Route path="questions" element={<Questions />} />
        <Route path="issues" element={<Issues />} />
        <Route path="quests" element={<Quests />} />
        <Route path="subjects" element={<ClassSubjects />} />
        <Route path="users" element={<Users />} />
        <Route path="leads" element={<Leads />} />
        <Route path="leads/:leadId/planBuilder" element={<PlanBuilder />} />
        <Route
          path="leads/:leadId/planBuilder/payment"
          element={<PaymentPlanPage />}
        />

        <Route path="learners" element={<Learners />} />
        <Route path="learners/:learnerId/profile" element={<Profile />} />

        <Route path="quests/:questId" element={<QuestDetails />} />
        <Route
          path="quests/:questId/stories/:storyId"
          element={<StoryDetails />}
        />
        <Route path="classes/:className" element={<ClassSubjects />} />
        <Route path="classes/:className/:subjectName" element={<Content />} />

        {/* School Routes */}

        <Route path="schools" element={<Schools />} />
        <Route path="schools/:schoolId" element={<SchoolOverview />} />
        <Route
          path="schools/:schoolId/scheduler"
          element={<SchedulerOverview />}
        />
        <Route
          path="schools/:schoolId/startOlympiad"
          element={<StartSchoolOlympiadForm />}
        />
        <Route
          path="schools/:schoolId/grades/:className"
          element={<ClassroomAnalytics />}
        />
        <Route path="schools/:schoolId/teachers" element={<Teachers />} />
        <Route path="schools/:schoolId/students" element={<StudentsPage />} />
        {/* <Route
          path="schools/:schoolId/students/:studentId"
          element={<StudentFullReport />}
        /> */}
        <Route
          path="schools/:schoolId/teachers/:teacherId"
          element={<TeacherOverview />}
        />
        {/* <Route path="schools/:schoolId/students/:studentId" element={<QuizModal />} /> */}
      </Route>

      {/* Protected Route */}
      {/* <Route path="dashboard" element={<RequireAuth />}>
          <Route element={<Dashboard />}>
            <Route path="welcome" element={<Welcome />} />
          </Route>
        </Route> */}
    </Routes>
  );
}

export default App;
